<template>
  <div class="row mt5 mb5">
    <div class="col-3 m-auto">
      <div class="details-card details-card--white text-center">
        <span class="card_title d-block text--green"> Total Balance </span>
        <span class="card_subtitle d-block text--green">
          {{ accountState.totalBalance | currency(accountDetails.currency.currencySymbol) }}
        </span>
      </div>
    </div>
    <div class="col-3 m-auto">
      <div class="details-card details-card--white text-center">
        <span class="card_title d-block text--green"> Available Balance </span>
        <span class="card_subtitle d-block text--green">
          {{ accountState.availableBalance | currency(accountDetails.currency.currencySymbol) }}
        </span>
      </div>
    </div>
    <div class="col-3 m-auto" v-if="!isFixedAccount">
      <div class="details-card details-card--white text-center">
        <span class="card_title d-block text--green">
          Recommended Deposit Amount
        </span>
        <span class="card_subtitle d-block text--green">
          {{ accountState.recommendedDepositAmount | currency(accountDetails.currency.currencySymbol) }}
        </span>
      </div>
    </div>
    <div class="col-3 m-auto" v-if="doesAccountHaveBalance">
      <div class="details-card details-card--white text-center">
        <span class="card_title d-block text--green">
          Savings Goal Target
        </span>
        <span class="card_subtitle d-block text--green">
          {{ accountDetails.targetAmount | currency(accountDetails.currency.currencySymbol) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
// import ApiService from "@/core/services/api.service";
export default {
  props: {
    accountDetails: [Array, Object],
    accountState: [Array, Object],
  },
  data() {
    return {
      // accountState: {},
    };
  },
  computed: {
    doesAccountHaveBalance() {
      return (
        this.accountDetails.balance > 0 &&
        this.accountDetails.accountType === "Saving Plan"
      );
    },
    productType() {
      return this.accountDetails.productType.toUpperCase();
    },
    isFixedAccount() {
      return this.productType === "FIXEDDEPOSIT" ||
        this.productType === "FIXED DEPOSIT"
        ? true
        : false;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-green {
  color: #00ba88;
}
</style>
